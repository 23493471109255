import moment from 'moment';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { BusHistoryArrival, PredictionOnStop } from '../../types/busHistoryTypes';
import Utils from '../../utilities/utils';


interface TableProps {
    rows: BusHistoryArrival[];
    predictionsOnStops: PredictionOnStop[];
    selectedRow: BusHistoryArrival | undefined;
    setSelectedRow: (row: BusHistoryArrival) => void;
}

const styles = {
    table: {
        display: 'block',
        maxHeight: '350px',
        overflowY: 'scroll',
        fontSize: '13px',
    } as React.CSSProperties,
};

const BusHistoryArrivalsTable: React.FC<TableProps> = ({ rows, predictionsOnStops, selectedRow, setSelectedRow }) => {

    const getTableRow = (row: BusHistoryArrival, index: number) => {
        const predictionOnStop = predictionsOnStops.find(p => p.StopId === row.stopId && p.StopSequence === row.stopSequence);
        return <BusHistoryArrivalsTableRow
            key={index}
            row={row}
            prediction={predictionOnStop}
            displayPredictionCells={predictionsOnStops.length > 0}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
        />;
    };

    return (
        <div>
            <Table celled striped compact singleLine fixed sortable style={styles.table} className="semanticTable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>Stop<br />Id</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Sch<br />Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Act<br />Time</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Act<br />Delay</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={1}>Stop<br />Time</Table.HeaderCell>
                        {predictionsOnStops.length > 0 &&
                            <>
                                <Table.HeaderCell textAlign="left" width={1}>Pred<br />Time</Table.HeaderCell>
                                <Table.HeaderCell textAlign="left" width={1}>Pred<br />Delay</Table.HeaderCell>
                            </>
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map(getTableRow)}
                </Table.Body>
            </Table>
        </div>
    );
};

export default BusHistoryArrivalsTable;

interface RowProps {
    row: BusHistoryArrival;
    prediction: PredictionOnStop | undefined;
    displayPredictionCells: boolean;
    selectedRow: BusHistoryArrival | undefined;
    setSelectedRow: (row: BusHistoryArrival) => void;
}

const BusHistoryArrivalsTableRow: React.FC<RowProps> = ({ row, prediction, displayPredictionCells, selectedRow, setSelectedRow }) => {
    return (
        <Table.Row onClick={() => setSelectedRow(row)} active={selectedRow !== undefined && selectedRow.tripId === row.tripId && selectedRow.stopId === row.stopId && selectedRow.stopSequence == row.stopSequence}>
            <Table.Cell>
                {row.stopId}
            </Table.Cell>
            <Table.Cell>
                {moment.parseZone(row.scheduledTime).format('h:mm')}
            </Table.Cell>
            <Table.Cell>
                {moment.parseZone(row.actualTime).format('h:mm')}
            </Table.Cell>
            <Table.Cell>
                {Utils.convertSecondsToMinutes(row.delaySec)}
            </Table.Cell>
            <Table.Cell>
                {Utils.convertSecondsToMinutes(row.actualStopSec)}
            </Table.Cell>
            {displayPredictionCells &&
                <>
                    <Table.Cell>
                        {prediction ? moment.parseZone(prediction?.ArrivalTime).format('h:mm') : ''}
                    </Table.Cell>
                    <Table.Cell>
                        {prediction ? Utils.convertSecondsToMinutes(prediction?.DelaySec) : ''}
                    </Table.Cell>
                </>
            }
        </Table.Row>
    );
};